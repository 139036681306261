import React, {useState, useEffect} from 'react'
import Nav from './Nav'
import './Home.css'

function Home({handleKeyDown}) {

  useEffect(() => {
    document.title = localStorage.getItem('tabName') ? localStorage.getItem('tabName') : 'Mango AI'

    const favicon = document.querySelector('link[rel="icon"]')
    if (localStorage.getItem('tabIcon')) favicon.href = localStorage.getItem('tabIcon');
    else favicon.href = "/logo-blue.png"

    if (localStorage.getItem('panicKey')) window.addEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <div className='games-home'>
      <Nav />

      <div className="games-home-container"  >
        <h1>Welcome to <span style={{color: 'rgb(255, 69, 69)'}}>School Unlocked</span></h1>
        <p>enjoy your stay :)</p>
      </div>
    </div>
  )
}

export default Home