import React, { useState } from 'react'
import './UnSub.css'
import axios from 'axios'

function UnSub() {
  const [ email, setEmail ] = useState('')
  const [ message, setMessage ] = useState('')

  const unsubscribe = (e) => {
    e.preventDefault();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(email)) {
      axios.post(process.env.REACT_APP_FUNCTIONS_URL + '/unsubscribe', {
            email: email
        }).then(() => {
          setMessage('Successfully removed ' + email)
          setEmail('')
        }).catch((error) => {
          console.log(error)
          setMessage('Problem removing ' + email + '\n\n' + error.response.data.message)
        })
    } else {
      setMessage('Please enter a valid email')
    }
  }
  return (
    <div className='unsubscribe'>
      <div className="unsubscribe-container">
        <h1>Unsubscribe from our Newsletter</h1>
        <form onSubmit={unsubscribe} className="unsubscribe-form">
          <label htmlFor="emailInput" className="form-label">Email</label>
          <input
            id="emailInput"
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="form-input"
          />
          <button type="submit" className="unsubscribe-button">Unsubscribe</button>
        </form>
        {message && <div className="message">{message}</div>}
      </div>
    </div>
  )
}

export default UnSub