import React, {useState, useEffect} from 'react'
import './Settings.css'
import Nav from './Nav'

function Settings({handleKeyDown}) {
  const [tabName, setTabName] = useState('');
  const [tabIcon, setTabIcon] = useState('');
  const [panicKey, setPanicKey] = useState('');
  const [panicUrl, setPanicUrl] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault()
    const formName = e.target.name;
    const favicon = document.querySelector('link[rel="icon"]')
    console.log(e.target)
    switch (formName) {
      case 'tabName':
        localStorage.setItem('tabName', tabName)
        document.title = tabName ? tabName : 'Mango AI'
        break;
      case 'tabIcon':
        localStorage.setItem('tabIcon', tabIcon)
        if (tabIcon) favicon.href = tabIcon;
        else favicon.href = "/logo-blue.png"
        break;
      case 'panicKey':
        localStorage.setItem('panicKey', panicKey)
        window.addEventListener('keydown', handleKeyDown)
        break;
      case 'panicUrl':
        localStorage.setItem('panicUrl', panicUrl)
        break;
      case 'Default':
        localStorage.setItem('tabName', "")
        localStorage.setItem('tabIcon', "")
        document.title = ""
        favicon.href = ""
        break;
      case 'Google':
        localStorage.setItem('tabName', "Google")
        localStorage.setItem('tabIcon', "https://www.google.com/favicon.ico")
        document.title = "Google"
        favicon.href = "https://www.google.com/favicon.ico"
        break;
      case 'Drive':
        localStorage.setItem('tabName', "My Drive - Google Drive")
        localStorage.setItem('tabIcon', "https://ssl.gstatic.com/docs/doclist/images/drive_2022q3_32dp.png")
        document.title = "My Drive - Google Drive"
        favicon.href = "https://ssl.gstatic.com/docs/doclist/images/drive_2022q3_32dp.png"
        break;
      case 'Docs':
        localStorage.setItem('tabName', "Google Docs")
        localStorage.setItem('tabIcon', "https://ssl.gstatic.com/docs/documents/images/kix-favicon7.ico")
        document.title = "Google Docs"
        favicon.href = "https://ssl.gstatic.com/docs/documents/images/kix-favicon7.ico"
        break;
      case 'Gmail':
        localStorage.setItem('tabName', "Inbox (6,942)")
        localStorage.setItem('tabIcon', "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png")
        document.title = "Inbox (6,942)"
        favicon.href = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png"
        break;
      default:
        console.log('Unknown form submission');
    }
  }

  useEffect(() => {
    setTabName(localStorage.getItem('tabName'))
    setTabIcon(localStorage.getItem('tabIcon'))
    setPanicKey(localStorage.getItem('panicKey'))
    setPanicUrl(localStorage.getItem('panicUrl'))

    document.title = localStorage.getItem('tabName') ? localStorage.getItem('tabName') : 'Mango AI'

    const favicon = document.querySelector('link[rel="icon"]')
    if (localStorage.getItem('tabIcon')) favicon.href = localStorage.getItem('tabIcon');
    else favicon.href = "/logo-blue.png"

    if (localStorage.getItem('panicKey')) window.addEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <div className='games-settings' >
      <Nav />

      <div className="games-settings-container" >
        <h1>Settings</h1>
        <div className="tab-settings">
          <h2>Tab Cloaking</h2>
          <form onSubmit={handleSubmit} name='tabName' >
            <input
              type="text"
              placeholder='Google'
              value={tabName}
              onChange={(e) => setTabName(e.target.value)}
            />
            <button>Set Tab Name</button>
          </form>
          <form onSubmit={handleSubmit} name='tabIcon'>
            <input
              type="text"
              placeholder='Image url'
              value={tabIcon}
              onChange={(e) => setTabIcon(e.target.value)}
            />
            <button>Set Tab Icon</button>
          </form>
          <div className="tab-presets">
            <button onClick={handleSubmit} name='Default'>
              <img src="/logo-blue.png" alt="Mango AI" name='Default'/>
            </button>
            <button onClick={handleSubmit} >
              <img src="https://www.google.com/favicon.ico" alt="Google" name='Google'/>
            </button>
            <button onClick={handleSubmit} >
              <img src="https://ssl.gstatic.com/docs/doclist/images/drive_2022q3_32dp.png" alt="Drive" name='Drive'/>
            </button>
            <button onClick={handleSubmit} >
              <img src="https://ssl.gstatic.com/docs/documents/images/kix-favicon7.ico" alt="Docs" name='Docs'/>
            </button>
            <button onClick={handleSubmit} >
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png" alt="Gmail" name='Gmail'/>
            </button>
          </div>
        </div>
        <div className="panic-settings">
          <h2>Panic Keys</h2>
          <p>WARNING: Panic keys trigger whenever you click that key whenever you click it,<br /> including typing on this page, so set it to a key you don't click often.</p>
          <form onSubmit={handleSubmit} name='panicKey'>
            <input
              type="text"
              placeholder='k'
              value={panicKey}
              onChange={(e) => setPanicKey(e.target.value)}
            />
            <button>Set Panic Key</button>
          </form>
          <form onSubmit={handleSubmit} name='panicUrl'>
            <input
              type="text"
              placeholder='mangoai.co'
              value={panicUrl}
              onChange={(e) => setPanicUrl(e.target.value)}
            />
            <button>Set Panic URL</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Settings