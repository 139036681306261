import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import BIRDS from 'vanta/dist/vanta.birds.min';
import './NotFound.css';

function NotFound() {
  const vantaRef = useRef(null);
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    let vantaEffect = BIRDS({
      el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      birdSize: 1.50
    });
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <div className='not-found' ref={vantaRef}>
      <p>404 Page Not Found</p>
    </div>
  );
}

export default NotFound;
