import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select'
import toolLinks from '../backend/toollinks.json'
import './MainSearchbar.css';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

const Main_searchbar = ({ query, setQuery, setFilter, selectedTool, setSelectedTool }) => {
  const dropdownRef = useRef(null);
  const menuRef = useRef(null)
  const [ openMenu, setOpenMenu ] = useState(false)
  const [selectedPill, setSelectedPill] = useState('All');

  const handleFilter = (tool) => {
    setSelectedTool(tool.text)
    setOpenMenu(false)
    setQuery('1~' + tool.text)
  }

  const handlePillClick = (filterValue) => {
    setSelectedPill(filterValue);
    setFilter(filterValue);
  };

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (!menuRef.current.contains(event.target) && !event.target.closest('.filter')) {
  //       setOpenMenu(false);
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => document.removeEventListener('mousedown', handleClickOutside);
  // }, []);

  return (
   <div>
    <div className="searchbar-area">
      <div className="searchbar">
        <SearchIcon className="search-icon" />
        <input
          type="text"
          className="searchbar-input-box"
          placeholder="ChatGPT"
          value={query.includes('~') ? '' : query}
          onChange={e => {
            setQuery(e.target.value)
            }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.currentTarget.blur()
            }
          }}
        />
      </div>

      {/* <div className="all-filters">
        <div className="filter" onClick={() => setOpenMenu(!openMenu)} >
          <TuneIcon className="tune-icon" />
        </div>
        <div
          className={`filter-menu ${openMenu ? 'open' : ''}`}
          style={{display: 'flex'}}
          ref={menuRef}>
          <div className="filter-container">
            {toolLinks.map(tool => (
              <div className={`filter-tool  ${selectedTool === tool.text ? 'selected' : ''}`} onClick={() => handleFilter(tool)} >
                <h3>{tool.emoji}{tool.text}</h3>
              </div>
            ))}
          </div>
        </div>
      </div> */}




      {/* <div className="filter" ref={dropdownRef}>
        <div className='select'>
          <Select
            isSearchable={false}
            placeholder='All'
            styles={{
              control: (provided) => ({
                ...provided,
                width: '8vw',
                height: 'calc(2vw + 22px)',
                minHeight: '48px',
                borderRadius: '10px',
                minWidth: '125px',
                border: '1px solid var(--border-color)',
              }),
            }}
            options={[
              { label: 'All', value: 'All' },
              { label: 'Free', value: 'Free' },
              { label: 'Freemium', value: 'Mid' },
              { label: 'Paid', value: 'Paid' }
            ]}
            onChange={handleFilter}
            />
        </div>
      </div> */}
    </div>

    <div className="pills-container">
        <button
          className={`pill ${selectedPill === 'All' ? 'selected' : ''}`}
          onClick={() => handlePillClick('All')}
        >
          All
        </button>
        <button
          className={`pill ${selectedPill === 'Free' ? 'selected' : ''}`}
          onClick={() => handlePillClick('Free')}
        >
          Free
        </button>
        <button
          className={`pill ${selectedPill === 'Mid' ? 'selected' : ''}`}
          onClick={() => handlePillClick('Mid')}
        >
          Freemium
        </button>
        <button
          className={`pill ${selectedPill === 'Paid' ? 'selected' : ''}`}
          onClick={() => handlePillClick('Paid')}
        >
          Paid
        </button>
      </div>

</div>

  );
};

export default Main_searchbar;
