import React, { useEffect, useState } from 'react';
import Website from '../components/Website';
import './Bookmarks.css';

const Bookmarks = () => {
   const [bookmarks, setBookmarks] = useState([]);

   useEffect(() => {
      const books = async () => {
         let sites = Object.values(await import('../backend/websites2.json')).slice(-1)[0];
         const existingData = localStorage.getItem('bookmarkData');
         const bookmarkData = existingData ? JSON.parse(existingData) : {};

         for (const filteredObj of sites) {
            if (bookmarkData.hasOwnProperty(filteredObj.name)) {
               filteredObj.marked = true;
            }
         }
         const filteredBookmarks = sites.filter(filteredObj => filteredObj.marked);
         setBookmarks(filteredBookmarks);
      }
      books()
      window.scrollTo({ top: 0, behavior: 'instant'})

   }, [])

   return (
      <div className='home'>
         <div className='home_container'>
            <div className='all_bookmarks'>
               {bookmarks.length > 0 ? (
                  bookmarks.map(site => (
                  <Website
                     key={site.name}
                     site={site}
                  />
               ))) : (
                  <div className="no_bookmarks">
                     No Bookmarks Found.
                  </div>
               )
            }
            </div>
         </div>
      </div>
   );
}

export default Bookmarks;
