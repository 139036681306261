import React, { useEffect, useState, useRef } from 'react';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import { Ring } from '@uiball/loaders';
import './Newsletter.css';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const Newsletter = ({setOpenPopup}) => {
   const searchRef = useRef(null);
   // const recaptchaRef = useRef();
   const [email, setEmail] = useState('');
   const [err, setErr] = useState('');
   const [success, setSuccess] = useState(false);
   const [animationActive, setAnimationActive] = useState(false);
   const [ loading, setLoading ] = useState(false)
   const [ serverError, setServerError ] = useState(false)

   const handleSubmit = (e) => {
      e.preventDefault();
      try {
         const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         if (regex.test(email)) {
            setErr('');
            setLoading(true);

            // const captchaValue = recaptchaRef.current.getValue();
            // if (captchaValue.length < 1) {
            //    setLoading(false);
            //    return setErr('sorry robots are not allowed');
            // }

            axios.post(process.env.REACT_APP_FUNCTIONS_URL + '/confirmation', {
               email: email,
               // captchaValue: captchaValue
            }).then(() => {
               setErr('');
               setLoading(false);
               setSuccess(true);
            }).catch((error) => {
               setErr('');
               setLoading(false);
               setServerError(error.response.data);
            })
            return
         } else if (email === '') {
            return setErr('');
         } else {
            return setErr('Please Enter a Valid Email');
         }
      } catch (error) {
         setLoading(false)
         return setErr(error.data);
      }
   };

   useEffect(() => {
      if (email === '') {
         setErr('');
      }
   }, [email]);

   const handleOutsideClick = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
         setSuccess(false);
         setServerError("");
         setEmail('');
      }
   };

   useEffect(() => {
      window.addEventListener('mousedown', handleOutsideClick);
      return () => {
         window.removeEventListener('mousedown', handleOutsideClick);
      };
   }, []);

   return (
      <div className="newsletter">
         <div
            className={`rectangle ${animationActive ? 'animate-border' : ''}`}>
            <div className="content-container">
               {success ? (
                  <div className="success-container">

                     <MarkEmailUnreadOutlinedIcon />
                     <h1>Now Check Your Email</h1>
                     <p>Verify your email to subscribe</p>
                     <p style={{fontSize: 'calc(16px + 0.05vw)'}} >Sent to <u>{email}</u></p>
                     <button className='success-button' onClick={() => {setSuccess(false); setEmail(''); setOpenPopup('');}}>Close</button>
                  </div>
               ) : loading ? (
                  <div className="success-container">
                    <Ring />
                  </div>
               ): serverError ? (
                  <div className='success-container' >
                     <h1>{serverError}</h1>
                     <p>Please try again in a couple minutes</p>
                     <button className='success-button' onClick={() => {setServerError(""); setEmail(''); setOpenPopup('');}}>Close</button>
                  </div>
               ) : (
                  <>
                     <div className='xbutton' onClick={() => setOpenPopup('')}>x</div>
                     <div className="text-container">
                        <h2>Join Our Newsletter!</h2>
                        <p>
                           Discover the latest AI innovations and join over 40,000+ reading the free newsletter!
                        </p>
                     </div>
                     <form className='newsletter-form' onSubmit={handleSubmit} >
                        <div className="input-container gradient-border">
                           <input
                              type="text"
                              className="input-box"
                              placeholder="email@example.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                           />
                           <button className="subscribe-button" >
                              <b>Subscribe</b>
                           </button>
                        </div>
                        {/* <ReCAPTCHA
                           ref={recaptchaRef}
                           sitekey="6LeJMTAqAAAAABE6QnkOBEztSotmTrtywVR1qd0E"
                        /> */}
                     </form>

                     <div className="error_container">
                        <p className="error_message">{err}</p>
                     </div>
                  </>
               )}
            </div>
         </div>
      </div>
   );

};

export default Newsletter;
