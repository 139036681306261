import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './Nav.css'

function Nav() {
  const location = useLocation();

  return (
    <div className='games-nav' >
      <Link to='/schoolunlocked/' style={location.pathname === '/schoolunlocked/' ? {color: 'black', backgroundColor: 'rgb(255, 69, 69)'} : {}} >
        Home
      </Link>
      <p>/</p>
      <Link to='/schoolunlocked/games' style={location.pathname === '/schoolunlocked/games' ? {color: 'black', backgroundColor: 'rgb(255, 69, 69)'} : {}}>
        Games
      </Link>
      <p>/</p>
      <Link to='/schoolunlocked/apps' style={location.pathname === '/schoolunlocked/apps' ? {color: 'black', backgroundColor: 'rgb(255, 69, 69)'} : {}}>
        Apps
      </Link>
      <p>/</p>
      <Link to='/schoolunlocked/settings' style={location.pathname === '/schoolunlocked/settings' ? {color: 'black', backgroundColor: 'rgb(255, 69, 69)'} : {}}>
        Settings
      </Link>
    </div>
  )
}

export default Nav