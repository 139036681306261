import React, { useEffect, useState } from 'react'
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import Home from '../pages/Home'
import { Ring } from '@uiball/loaders';
import { auth } from '../backend/firebase'
import jwt_decode from "jwt-decode";
import './Confirm.css'

let paragraph = "";

const Confirm = ({ query, setQuery, filteredSites, selectedTool, setSelectedTool, small, setFilter }) => {
  const [ error, setError ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true)
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const email = jwt_decode(token, 'secret').email;

    if (email) {
      fetchSignInMethodsForEmail(auth, email)
        .then((methods) => {
          if (methods && methods.length > 0) {
            setError('Email already subscribed');
            paragraph = "Try a different email"
            setLoading(false)
          } else {
            createUserWithEmailAndPassword(auth, email, 'password')
              .then(() => {
               setError(
                  <>
                    Welcome to the <br /> Mango AI Newsletter
                  </>
                );
                paragraph = "Newsletter updated weekly (:"
                setLoading(false)
              })
              .catch((error) => {
                console.error('Sign-in error:', error);
                setError('Subscription Error. Please try again.');
                setLoading(false)
              });
          }
        })
        .catch((error) => {
          console.error('Error checking email existence:', error);
          setError('An error occurred. Please try again later.');
          setLoading(false)
        });
    }
  }, []);

  return (
    <div className="confirm">
      <div className="confirm_background" onClick={() => navigate('/')} onTouch={() => navigate('/')} >
        <div className="confirm_container">
          {loading ? <Ring /> : (
            <div className="confirm_content" >
              <h2 style={error !== "Email already subscribed" ? { marginLeft: '17px', marginRight: '17px' } : {}}> {error} </h2>
              <p style={{textAlign: 'center'}} >{paragraph}</p>
              <button>Close</button>
            </div>
          )}
        </div>
      </div>
      <Home query={query} setQuery={setQuery} filteredSites={filteredSites} selectedTool={selectedTool} setSelectedTool={setSelectedTool} small={small} setFilter={setFilter} loading={loading} setLoading={setLoading} />
    </div>

  )
}

export default Confirm