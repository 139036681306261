import React from 'react';
import './HomeTitle.css';

function Title() {
    return (
      <div class="title-container">
         <h1>Discover the best in <span class="boujee-text-hometitle">AI</span></h1>
         <p class="subtitle">Search from over 14,000 AI Tools.</p>
      </div>
    );
}

export default Title;
