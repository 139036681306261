import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import Nav from './Nav';
import AdsComponent from '../../components/AdsComponent';
import './Play.css'

function Play ({handleKeyDown}) {
  const location = useLocation();

  const {gameTitle, gameImage, gameUrl, gameDescr} = location.state || {};

  useEffect(() => {
    document.title = localStorage.getItem('tabName') ? localStorage.getItem('tabName') : 'Mango AI'

    const favicon = document.querySelector('link[rel="icon"]')
    if (localStorage.getItem('tabIcon')) favicon.href = localStorage.getItem('tabIcon');
    else favicon.href = "/logo-blue.png"

    if (localStorage.getItem('panicKey')) window.addEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <div className='games-play' >
      <Nav />
      <div className="games-play-container">
        <div className="playing">
          <iframe src={gameUrl} frameborder="0" className='game-iframe' title='frame' width='100%' height='100%' allowFullScreen></iframe>
        </div>
        <div className="play-info">
          <h1><img src={gameImage} alt={gameTitle + 'Image'} />{gameTitle}</h1>
          <p>{gameDescr}</p>
          <AdsComponent dataAdSlot='5844309880' school={true} />
        </div>
      </div>
      <div className="more-ads">
        <AdsComponent dataAdSlot='5844309880' school={true} />
      </div>
    </div>
  )
}

export default Play