import React, { useEffect } from 'react';

const PopupAds = ({dataAdSlot, school=false, name}) => {

   useEffect(() => {
       try {
           (window.adsbygoogle = window.adsbygoogle || []).push({});
       } catch (e) {
           console.error(e);
       }
   }, []);

   return (
    <>
        <ins
            className="adsbygoogle"
            style={{ display: 'block', maxWidth: '100%' }}
            data-ad-client={"ca-pub-4534027083663191"}
            data-ad-slot={dataAdSlot}
            data-ad-format={"fluid"}
        ></ins>
    </>

   );
};

export default PopupAds;
