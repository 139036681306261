// Inspiration: https://github.com/g-delmo/use-draggable-scroll

export default function useDraggableScroll(
  ref,
  options = { direction: 'horizontal' }
) {
  if (process.env.NODE_ENV === 'development') {
    if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
      console.error('`useDraggableScroll` expects a single ref argument.');
    }
  }

  const { direction } = options;
  let initialPosition = { scrollLeft: 0, touchX: 0 };

  const touchMoveHandler = (event) => {
    if (ref.current && event.touches.length === 1) {
      const dx = event.touches[0].clientX - initialPosition.touchX;

      if (direction !== 'vertical') {
        ref.current.scrollLeft = initialPosition.scrollLeft - dx;
      }
    }
  };

  const touchEndHandler = () => {
    if (ref.current) ref.current.style.cursor = 'grab';

    document.removeEventListener('touchmove', touchMoveHandler);
    document.removeEventListener('touchend', touchEndHandler);

    window.removeEventListener('touchmove', preventDefaultYScroll);
  };

  const preventDefaultYScroll = (event) => {
    event.preventDefault();
  };

  const onTouchStart = (event) => {
    if (ref.current && event.touches.length === 1) {
      // Save the position at the moment the touch starts
      initialPosition = {
        scrollLeft: ref.current.scrollLeft,
        touchX: event.touches[0].clientX,
      };

      // Show a cursor: grabbing style and set user-select: none to avoid highlighting text while dragging
      ref.current.style.cursor = 'grabbing';
      ref.current.style.userSelect = 'none';

      // Add the touch event listeners that will track the touch position for the rest of the interaction
      document.addEventListener('touchmove', touchMoveHandler);
      document.addEventListener('touchend', touchEndHandler);

      // Disable scrolling in the vertical direction on the whole page
      window.addEventListener('touchmove', preventDefaultYScroll, { passive: false });
    }
  };

  const mouseMoveHandler = (event) => {
    if (ref.current) {
      // Calculate differences to see how far the user has moved
      const dx = event.clientX - initialPosition.mouseX;

      // Scroll the element horizontally according to the difference
      if (direction !== 'vertical') {
        ref.current.scrollLeft = initialPosition.scrollLeft - dx;
      }
    }
  };

  const mouseUpHandler = () => {
    // Return to cursor: grab after the user is no longer pressing
    if (ref.current) ref.current.style.cursor = 'grab';

    // Remove the event listeners since it is not necessary to track the mouse position anymore
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const onMouseDown = (event) => {
    if (ref.current) {
      // Save the position at the moment the user presses down
      initialPosition = {
        scrollLeft: ref.current.scrollLeft,
        mouseX: event.clientX,
      };

      // Show a cursor: grabbing style and set user-select: none to avoid highlighting text while dragging
      ref.current.style.cursor = 'grabbing';
      ref.current.style.userSelect = 'none';

      // Add the event listeners that will track the mouse position for the rest of the interaction
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  };

  return { onTouchStart, onMouseDown };
}
