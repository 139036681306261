import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import AdsComponent from '../../components/AdsComponent'
import PopupAds from '../../components/PopupAds'
import Nav from './Nav'
import './Games.css'
import GamesData from './Games.json'

function Games({handleKeyDown}) {

  const [query, setQuery] = useState('')
  const [ openPopup, setOpenPopup ] = useState('');
  const [ counter, setCounter ] = useState(0);
  const [ buttonDisabled, setButtonDisabled ] = useState(true);
  const [ allGameData, setAllGameData ] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      return () => {
        clearInterval(timer)
      }
    } else if (counter === 0) {
      setButtonDisabled(false)
    }
  }, [counter])

  const handleClick = (gameTitle, gameImage, gameUrl, gameDescr) => {
    setAllGameData({gameTitle, gameImage, gameUrl, gameDescr})
    setOpenPopup('open-popup')
    setButtonDisabled(true)
    setCounter(3);
  }

  useEffect(() => {
    document.title = localStorage.getItem('tabName') ? localStorage.getItem('tabName') : 'Mango AI'

    const favicon = document.querySelector('link[rel="icon"]')
    if (localStorage.getItem('tabIcon')) favicon.href = localStorage.getItem('tabIcon');
    else favicon.href = "/logo-blue.png"

    if (localStorage.getItem('panicKey')) window.addEventListener('keydown', handleKeyDown)
  }, [])

  useEffect(() => {
    document.querySelectorAll('.games-gamebox').forEach(function (item) {
      const title = item.querySelector('p').textContent.toLowerCase()

      if (title.includes(query.toLowerCase())) {
        item.style.display = 'block'
      } else {
        item.style.display = 'none'
      }
    })
  }, [query])

  return (
    <div className='games-games' >
      <Nav />
      <div className={openPopup && 'popup-background'} >
          <div className={"popup " + openPopup} >
            {openPopup && <PopupAds dataAdSlot='7739134073' school={true}/>}
            <div className='xbutton' onClick={() => setOpenPopup('')}>x</div>
            <button className='popup-button' onClick={() => {console.log(allGameData); navigate('/schoolunlocked/play', {state: allGameData})}} disabled={buttonDisabled} >{counter > 0 ? 'Loading Site ' + counter : 'Continue To Site'}</button>
          </div>
      </div>
      <div className="games-container">
        <div className="games-games-container">
          <h1 >Games</h1>
          <input
            type="text"
            placeholder='search'
            value={query}
            onChange={e => setQuery(e.target.value)}
            />
          <div className="games-grid">
            {GamesData.sort((a, b) => a.Title.localeCompare(b.Title)).map((game, index) => (
              <div key={index} className="games-gamebox" onClick={() => handleClick(game.Title, game.Image, game.Url, game.Description)} >
                <img src={game.Image} alt={game.Title} loading='lazy' />
                <p style={{margin: '10px'}} >{game.Title}</p>
              </div>
            ))}
          </div>
          <div className="more-ads">
            <AdsComponent dataAdSlot='7739134073' school={true} />
          </div>
        </div>
      </div>

    </div>
  )
}

export default Games