import React, { useEffect, useRef } from 'react';
import Website from '../components/Website';
import Newsletter from '../components/Newsletter';
import HomeTitle from '../components/HomeTitle';
import MainSearchbar from '../components/MainSearchbar';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useDraggableScroll from '../backend/draggable';
import { useNavigate } from 'react-router-dom';
import toolLinks from '../backend/toollinks.json'
import './Home.css';
import { Ring } from '@uiball/loaders';
import { useLocation } from 'react-router-dom';

function Home({ query, setQuery, filteredSites, selectedTool, setSelectedTool, small, setFilter, loading, setLoading }) {
  const containerRef = useRef(null);
  const itemRef = useRef(null);
  const navigate = useNavigate()
  const { onTouchStart, onMouseDown } = useDraggableScroll(containerRef, { direction: 'horizontal' });

  const location = useLocation(); // Step 3

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get('web'); // Assuming your URL parameter is named 'query'
    console.log(searchQuery)
    if (searchQuery) {
      setQuery(searchQuery); // Step 5: Use the query parameter value
    }
  }, [location.search, setQuery]);

//   const handleClickScroll = (left) => {
//     const compW = parseFloat(getComputedStyle(containerRef.current.parentElement).width);
//     const scrollOffset = left ? -0.69 * compW + containerRef.current.scrollLeft : 0.69 * compW + containerRef.current.scrollLeft;
//     containerRef.current.scrollTo({
//       left: scrollOffset,
//       behavior: 'smooth',
//     });
//   };

//   const handleToolClick = (index, tool) => {
//     setQuery("1~" + tool.text.slice(1, 100));
//     setSelectedTool(index);
//   };

//   useEffect(() => {
//     if (selectedTool !== null) {
//       let scrollOffset = 0;
//       for (let i = 0; i < selectedTool; i++) {
//         scrollOffset += containerRef.current.children[i].offsetWidth+10;
//       }
//       scrollOffset -= 25 * window.innerWidth / 100 + 50;
//       containerRef.current.scrollTo({
//         left: scrollOffset,
//         behavior: 'smooth',
//       });
//     }
//   }, [selectedTool])

  useEffect(() => {
    if (filteredSites.length === 0) setLoading(false)
  }, [filteredSites])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant'})
  }, [])

  return (
    <div className='home'>
      <div className="home_container">

      <HomeTitle/>

        {/* <Newsletter/> */}

        {/* <div className="tools">
          <KeyboardArrowLeftIcon className='arrow left'
          onClick={() => handleClickScroll(true)}
          style={small ? {display: 'none'} : {display: 'block'}} />
          <div className="tool_boxes" ref={containerRef} onTouchStart={onTouchStart} onMouseDown={onMouseDown} >
            {toolLinks.map((tool, index) => (
              <span
                key={tool.text}
                onClick={() => handleToolClick(index, tool)}
                className={selectedTool === index ? "selected" : ""}
                ref={itemRef} >
                {tool.emoji}
                {tool.text}
              </span>
            ))}
          </div>
          <KeyboardArrowRightIcon className='arrow right'
          onClick={() => handleClickScroll(false)}
          style={small ? {display: 'none'} : {display: 'block'}} />
        </div> */}

        <MainSearchbar query={query} setQuery={setQuery} selectedTool={selectedTool} setSelectedTool={setSelectedTool} setFilter={setFilter} />

        <div className="all_websites">
          {filteredSites.map((site) => (
            <Website key={Math.random()} site={site} />
          ))}
          {filteredSites.length === 0 && (
            <div className='no-results' >
              <p className='loader' >No Results</p>
              {/* <button onClick={() => navigate('/submit')} >Submit a Website</button> */}
            </div>
          )}
          {loading && (
            <div className="loader">
              <Ring size={42.069}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;