import React from 'react';

function Terms() {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>
        Welcome to our website. If you continue to browse and use this website,
        you are agreeing to comply with and be bound by the following terms and
        conditions of use, which together with our privacy policy govern
        Mango AI's relationship with you in relation to this website.
      </p>
      <p>
        The use of this website is subject to the following terms of service:
        <ul>
          <li>The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.</li>
          <li>This website uses cookies to monitor browsing preferences. If you do
          allow cookies to be used, personal information may be stored by us for
          use by third parties.</li>
          <li>Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability of
          the information and materials found or offered on this website for any
          particular purpose.</li>
          <li>Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your own
          responsibility to ensure that any products, services or information
          available through this website meet your specific requirements.</li>
          <li><strong>Acceptance of Terms:</strong> By accessing and using this website, you acknowledge that you have read, understood, and agree to be bound by these terms.</li>

          <li><strong>Description of Service:</strong> This website provides a collection of AI tools and resources for informational purposes. The tools are intended to assist users in understanding and utilizing AI technology.</li>

          <li><strong>User Conduct and Responsibilities:</strong>
              <ul>
                  <li>Users agree not to use the website for illegal purposes or in ways that could damage, disable, overburden, or impair the site.</li>
                  <li>Users are responsible for maintaining the confidentiality of their account information and are liable for all activities under their account.</li>
                  <li>Users must provide accurate and current information and are responsible for updating this information as necessary.</li>
              </ul>
          </li>

          <li><strong>Intellectual Property Rights:</strong>
              <ul>
                  <li>All content, including text, graphics, logos, and software, is the property of Mango AI and is protected by copyright and other intellectual property laws.</li>
                  <li>User-generated content remains the intellectual property of the user, but by posting content, users grant Mango AI a non-exclusive, worldwide, royalty-free license to use, display, and reproduce the content.</li>
              </ul>
          </li>

          <li><strong>User-Generated Content:</strong> Users may submit content or participate in discussions. Content must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable.</li>

          <li><strong>No Unlawful or Prohibited Use:</strong> Users agree not to use the website for any purpose that is unlawful or prohibited by these terms.</li>

          <li><strong>Links to Third-Party Sites:</strong> The website may contain links to external websites not operated by us. We are not responsible for the content, privacy policies, or practices of any third-party sites.</li>

          <li><strong>Disclaimer of Warranties:</strong>
              <ul>
                  <li>The website is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability or fitness for a particular purpose.</li>
                  <li>We do not warrant that the website will be uninterrupted or error-free, that defects will be corrected, or that the site is free of viruses or other harmful components.</li>
              </ul>
          </li>

          <li><strong>Limitation of Liability:</strong> In no event will Mango AI be liable for any indirect, incidental, special, consequential or punitive damages arising out of the use of or inability to use the website, even if we have been advised of the possibility of such damages.</li>

          <li><strong>Indemnification:</strong> You agree to indemnify and hold harmless Mango AI, its officers, directors, employees, and agents from and against any claims, actions, or demands, including without limitation reasonable legal and accounting fees, arising or resulting from your breach of this Agreement or your access to, use, or misuse of the Content or Site.</li>

          <li><strong>Modification of Terms:</strong> Mango AI reserves the right, at its sole discretion, to modify or replace these Terms at any time. If the alterations constitute a material change, we will notify you by posting an announcement on the website.</li>

          <li><strong>Governing Law:</strong> These Terms will be governed by and construed in accordance with the laws of USA, without regard to its conflict of law provisions.</li>

          <li><strong>Contact Information:</strong> If you have any questions about these Terms, please contact us at mangoaico@gmail.com.</li>

          <li><strong>Effective Date:</strong> These terms are effective as of 11/28/2023.</li>

        </ul>
      </p>
      <h3>Disclaimer</h3>
      <p>
        The paths /wifimap, /facememojiremover, /censoredpartremover, /whatsapp,
        /whatsappnumber, /privateinsta, and /amazoncoupons mentioned on this website
        are not real websites but are instead prank websites. These websites are
        created for entertainment purposes only and are not intended for real use.
        Users are advised to use these websites responsibly and with the understanding
        that they are fictitious.
      </p>
    </div>
  );
}

export default Terms;
