import React, { useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AdsComponent from '../components/AdsComponent';
import PopupAds from '../components/PopupAds';
import './Website.css';

function Website({ site }) {
  const [ marked, setMarked ] = useState(site.marked)
  const [ openPopup, setOpenPopup ] = useState('');
  const [ counter, setCounter ] = useState(0)
  const [ buttonDisabled, setButtonDisabled ] = useState(true)

  const dataAdSlotMap = {
   "AI Home Design": "8456126432",
   "Gamma": "8707407877",
   "Games Frog": "8755509335",
   "Aniwatch": "5059806907",
   "Face Emoji Remover": "9554403927",
   "Tome": "1890718089",
   "Mixo": "9826662826",
   "Road Trippers": "5366928667",
   "Eat This Much": "8807480225",
   "Get Human": "4574336149",
   "Censored Part Remover": "9828917852",
   "Image Data Remover": "9928990203",
   "Google Driving Simulator": "6598235385",
   "Muscle WIKI": "4876183507",
   "Movie HUB": "5641350703",
   "Emu Games": "3088668547",
   "Skipit AI": "3150864838",
   "Looka": "8110675330",
   "Are you Gay?": "4743148100",
   "ChatPDF": "2116984767",
   "School Unlocked": "1454408279",
   "Kickresume": "1871913506",
   "Private Instagram Viewer": "2409672388",
   "Wifi Passwords": "4920720429",
   "UIBall": "5954340731",
   "Amazon Promo Code Finder": "8368471845",
   "Mind Luster": "5379346425",
   "WhatsApp Tracker": "9904052395",
   "Social Media Followers": "5867698874",
   "Social Media Views": "3874330047",
  };

  const handleToggle = () => {
   const existingData = localStorage.getItem('bookmarkData');
   const bookmarkData = existingData ? JSON.parse(existingData) : {};
   const isMarked = bookmarkData[site.name];

   if (isMarked) {
     delete bookmarkData[site.name];
     localStorage.setItem('bookmarkData', JSON.stringify(bookmarkData));
     site.marked = false
   } else {
     bookmarkData[site.name] = true;
     localStorage.setItem('bookmarkData', JSON.stringify(bookmarkData));
     site.marked = true
   }

   setMarked(site.marked);
 };

  useEffect(() => {
    setMarked(site.marked)
  });

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      return () => {
        clearInterval(timer)
      }
    } else if (counter === 0) {
      setButtonDisabled(false)
    }
  }, [counter])

   const handleClick = () => {
      setOpenPopup('open-popup')
      setButtonDisabled(true)
      setCounter(4);
   }

  return (
   <div className={site.featured ? "featured-border website_background" : " website_background"}>
      <div className={openPopup && 'popup-background'} >
         <div className={"popup " + openPopup} >
            {openPopup && <PopupAds dataAdSlot={dataAdSlotMap.hasOwnProperty(site.name) ? dataAdSlotMap[site.name] : 1924616911} name={site.name} />}
            <div id="popup_x_button" alt={site.name} className='xbutton' onClick={() => setOpenPopup('')}>x</div>
            <button
              id ={counter === 0 && "popup_site_button"}
              alt={site.name}
              className='popup-button'
              onClick={() => {
                if (site.name.toLowerCase().includes('social media')) localStorage.removeItem('k')
                // if (site.name === 'Wifi Passwords' ||
                //     site.name === 'Private Instagram Viewer' ||
                //     site.name === 'WhatsApp Tracker' ||
                //     site.name === 'Face Emoji Remover'
                // ) localStorage.setItem('finesse', 'true')
                window.open(site.website_link, '/');
              }}
              disabled={buttonDisabled}
              >{counter > 0 ? 'Loading Site ' + counter : 'Continue To Site'}</button>
         </div>
      </div>
      {site.name === "adbygoogle" ? (
        <div className="website_container">
          <AdsComponent dataAdSlot='8667879426' />
        </div>
      ) : (
        <div className="website_container" id={site.name.replace(/\s/g, '')}>
          <img
            src={site.image}
            alt={site.name}
            id="website_image"
            onClick={handleClick}
            loading="lazy"
            style={{objectFit: "cover"}}
          />
          <div className="headerandspan">
            <h2
              className={site.featured ? "boujee-text website_name" : "website_name"}
              id="website_name"
              alt={site.name}
              onClick={handleClick}
            >
              {site.name}
            </h2>
            <span className="tag">
              <p className='site_cost' >{site.pricing_plan}</p>
            </span>
          </div>
          {/* <span className="tag">
            {site.tag}
            {site.featured ? (
              <p style={{ display: 'inline-block', margin: '0', paddingLeft: '10px' }}>✨ FEATURED</p>
            ) : null}
            <p className='site_cost' >{site.id === "Mid" ? "Freemium" : site.id}</p>
          </span> */}
          <p className="summary">{site.summary}</p>
          <div className="bookmark_learnmore" id="bookmark_learnmore" alt={site.name}>
            <button
              className="learnmore_button"
              id="learnmore_button"
              alt={site.name}
              style={{ color: 'black' }}
              onClick={handleClick}
              >
              Open Website <OpenInNewIcon className="MuiSvgIcon-learnmore" id="learnmore_icon" alt={site.name} />
            </button>
            <button className="bookmark_button" alt={site.name} style={{ color: 'black' }} onClick={handleToggle} disabled={site.tag.includes("News") ? true : false}>
              {marked ? <BookmarkIcon className="MuiSvgIcon-bookmarks" id="bookmark_icon" alt={site.name} /> : <BookmarkBorderIcon alt={site.name} />}
            </button>
          </div>
        </div>
      )}
    </div>
 );
}

export default Website;