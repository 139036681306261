import React, { useEffect } from 'react';
import './AdsComponent.css';

const AdsComponent = ({dataAdSlot, school=false}) => {

   useEffect(() => {
       try {
           (window.adsbygoogle = window.adsbygoogle || []).push({});
       } catch (e) {
           console.error(e);
       }
   }, []);

   return (
       <>
           <ins
               className="adsbygoogle ad_container"
               style={{ display: 'block' }}
               data-ad-client={"ca-pub-4534027083663191"}
               data-ad-slot={dataAdSlot}
               data-ad-format="fluid"
           ></ins>
       </>
   );
};

export default AdsComponent;
